export const TABLEAU_API_230 =
  'https://public.tableau.com/javascripts/api/tableau-2.3.0.js';

export const OPTIONS = {
  width: '100%',
  height: '727px',
  hideTabs: true,
  hideToolbar: true,
};

export const tableauUrlRegex =
  /^(https:)?\/\/public.tableau.com\/views\/.+%3AshowVizHome=no/;
