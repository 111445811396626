import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Grid, Typography, units } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';

import { useIntl } from 'ha/i18n';

import { TopicClusterType } from './types';
import { TopicCluster } from './TopicCluster';
import { CustomContainer } from '../CustomContainer';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.pxToRem(16),
    [theme.breakpoints.up('lg')]: {
      padding: units.pxToRem(24, 0, 52),
    },
  },
  title: {
    fontFamily: interFont.fontFamily,
    fontSize: units.pxToRem(18),
    lineHeight: 1.55,
    fontWeight: 400,
    letterSpacing: units.pxToRem(-0.6),
    marginBottom: units.pxToRem(12),
    color: theme.palette.grey[800],

    [theme.breakpoints.up('lg')]: {
      fontSize: units.pxToRem(24),
      lineHeight: 1.41,
      marginBottom: units.pxToRem(16),
    },
  },
  topicClustersList: {
    gap: units.pxToRem(8, 8),

    '& > div:first-of-type': {
      paddingRight: units.pxToRem(9),
      position: 'relative',
    },

    '& > div:first-of-type:after': {
      display: 'block',
      position: 'absolute',
      top: units.pxToRem(6),
      right: 0,
      content: "''",
      width: units.pxToRem(1),
      height: units.pxToRem(28),
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

interface Props {
  topicClusters: TopicClusterType[];
  isSelectedAll: boolean;
  handleSelectionAll: () => void;
}

const TopicClusters: React.FC<Props> = ({
  topicClusters = [],
  isSelectedAll,
  handleSelectionAll,
}) => {
  const { T } = useIntl();
  const { classes } = useStyles();

  if (!topicClusters.length) {
    return null;
  }

  return (
    <section data-test-locator="Blog/TopicClusters">
      <CustomContainer className={classes.container}>
        <Typography.H2 className={classes.title}>
          {T('blog.label_explore_topics')}
        </Typography.H2>
        <Grid
          container
          className={classes.topicClustersList}
          data-test-locator="Blog/TopicClusters/List"
        >
          <Grid item>
            <TopicCluster
              label={T('blog.topic.all_topics')}
              isSelected={isSelectedAll}
              handleSelection={handleSelectionAll}
            />
          </Grid>

          {topicClusters.map(({ label, isSelected, handleSelection }) => (
            <Grid item key={label}>
              <TopicCluster
                label={label}
                isSelected={isSelected}
                handleSelection={handleSelection}
              />
            </Grid>
          ))}
        </Grid>
      </CustomContainer>
    </section>
  );
};

export { TopicClusters };
