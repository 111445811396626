export const isArticleUpdated = (createdAt?: string, updatedAt?: string) => {
  if (!createdAt || !updatedAt) {
    return undefined;
  }

  const createdAtDate = new Date(createdAt);
  const updatedAtDate = new Date(updatedAt);

  const differenceInTime = updatedAtDate.getTime() - createdAtDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays > 1;
};
