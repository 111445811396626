const urlRegex = /^(https:)\/\/open.spotify.com\/episode\/(.+)/;

// https://open.spotify.com/episode/3izYkwiL710aKWv4Ey2DxF
export function isSpotifyEpisode(url: string) {
  return urlRegex.test(url);
}

// https://open.spotify.com/embed/episode/3izYkwiL710aKWv4Ey2DxF
export function createEmbedUrl(url: string) {
  const groups = urlRegex.exec(url);

  if (!groups) {
    return null;
  }

  return `https://open.spotify.com/embed/episode/${groups[2]}`;
}
