import React from 'react';
import { RenderDangerously } from 'ha/helpers/RenderDangerously';

import { useServices } from 'ha/services';

interface Props {
  src: string;
}

const InstagramCard: React.FC<Props> = ({ src }) => {
  const { facebook } = useServices();
  const [embedContent, setEmbedContent] = React.useState('');

  React.useEffect(() => {
    if (src) {
      facebook
        .getEmbedContend(src)
        .then(html => setEmbedContent(html))
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).instgrm?.Embeds?.process();
        });
    }
  }, [src, facebook]);

  return embedContent ? (
    <RenderDangerously renderWith="span">{embedContent}</RenderDangerously>
  ) : null;
};

export { InstagramCard };
