import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Container, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: units.pxToRem(1016),
      padding: 0,
    },
  },
}));

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const CustomContainer: React.FC<Props> = ({ className, children }) => {
  const { classes, cx } = useStyles();

  return (
    <Container className={cx(classes.container, className)}>
      {children}
    </Container>
  );
};
