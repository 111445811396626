import React from 'react';

import { HubspotFormContainer as HubspotForm } from 'ha/modules/HubspotForm';
import { extractHubspotFormParameters } from 'ha/utils/thirdParty/extractHubspotFormParameters';

const MarkdownCode: React.FC<React.PropsWithChildren> = ({ children }) => {
  const code = Array.isArray(children) ? children[0] : children;

  if (typeof code === 'string') {
    const params = extractHubspotFormParameters(code);

    // if hubspot code for embedding the form is provided, render the form
    // instead of plain text, it's a hacky solution, but simplifies the flow
    // for content creators and marketing team, allowing to copy/paste embed code
    // directly, instead of creating custom markdown elements or allowing to run
    // scripts from markdown which can produce security issues
    if (params) {
      return <HubspotForm portalId={params.portalId} formId={params.formId} />;
    }
  }

  return <code>{children}</code>;
};

export { MarkdownCode };
