import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/core';

import { createEmbedUrl } from 'ha/utils/thirdParty/spotify';

interface Props {
  src: string;
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'block',
    position: 'relative',
    height: units.pxToRem(232),

    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      borderRadius: units.pxToRem(12),
    },
  },
}));

const SpotifyCard: React.FC<Props> = ({ src }) => {
  const { classes } = useStyles();
  const embedUrl = createEmbedUrl(src);

  if (!embedUrl) {
    return null;
  }

  return (
    <span className={classes.container}>
      <iframe
        title="Spotify card"
        src={embedUrl}
        frameBorder="0"
        allowFullScreen
        data-test-locator="SpotifyCard iframe"
      />
    </span>
  );
};

export { SpotifyCard };
