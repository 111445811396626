import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { IconButton, units } from '@hbf/dsl/core';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

export const useStyles = makeStyles()(theme => ({
  link: {
    width: units.pxToRem(40),
    height: units.pxToRem(40),
    color: theme.palette.secondary.main,
  },
}));

interface Props {
  socialVendor: string;
  href: string;
}

export const SocialShareButton: React.FC<React.PropsWithChildren<Props>> = ({
  socialVendor,
  href,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <Track
      type={socialVendor}
      name="Clicked a share button"
      socialvendor={socialVendor}
    >
      <IconButton
        className={classes.link}
        component="a"
        href={href}
        rel="noopener noreferrer nofollow"
        target="_blank"
        title={socialVendor}
      >
        {children}
      </IconButton>
    </Track>
  );
};
