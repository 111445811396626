import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { createEmbedUrl } from 'ha/utils/thirdParty/youtubeUrl';

interface Props {
  src: string;
}

const useStyles = makeStyles()({
  container: {
    display: 'block',
    position: 'relative',
    height: 0,
    paddingTop: 0,
    paddingBottom: '56.25%' /* 16:9 */,

    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
  },
});

export const YoutubeCard: React.FC<Props> = ({ src }) => {
  const { classes } = useStyles();
  const embedUrl = createEmbedUrl(src);

  return embedUrl ? (
    // div breaks ssr when used inside "p" tag
    <span className={classes.container}>
      <iframe src={embedUrl} frameBorder="0" allowFullScreen />
    </span>
  ) : null;
};
