const scriptRegex =
  /<script>\s+?hbspt.forms.create\({\s+?portalId: "(.+?)",\s+?formId: "(.+?)"\s+?}\);\s+?<\/script>/;

const extractHubspotFormParameters = (code: string) => {
  const groups = scriptRegex.exec(code);

  if (groups && groups[1] && groups[2]) {
    return {
      portalId: groups[1],
      formId: groups[2],
    };
  }

  return null;
};

export { extractHubspotFormParameters };
