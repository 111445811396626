import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/legacy';

const useStyles = makeStyles()({
  root: {
    marginBottom: '1rem',
  },
});

export const MarkdownH3: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Typography.H3 component="h3" className={classes.root}>
      {children}
    </Typography.H3>
  );
};
