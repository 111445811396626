import React from 'react';

import { BlogPageData } from '@ha/contentful';
import { Typography, units } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { Markdown } from 'ha/components/Markdown/Markdown';

interface Props {
  disclaimers: BlogPageData['disclaimers'];
}

const Disclaimers: React.FC<Props> = ({ disclaimers }) => {
  const { T } = useIntl();

  if (!disclaimers) {
    return null;
  }

  return (
    <Grid
      container
      flexDirection="column"
      rowGap={units.pxToRem(4)}
      padding={units.pxToRem(16)}
      mb={units.pxToRem(32)}
      border={1}
      borderColor="mono.dark"
      borderRadius={units.pxToRem(8)}
      color="secondary.main"
    >
      <Typography component="p" variant="body/sm-semibold">
        {T('blog.article_disclaimer')}
      </Typography>
      {disclaimers.map(({ description }) => (
        <Markdown
          key={description}
          content={description}
          components={{
            p: ({ children }: { children: React.ReactNode }) => (
              <Typography component="p" variant="body/sm-regular">
                {children}
              </Typography>
            ),
          }}
        />
      ))}
    </Grid>
  );
};

export { Disclaimers };
