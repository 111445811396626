import React from 'react';

import {
  BlogPageData,
  BlogType,
  getTenantsGlobalBlogPath,
} from '@ha/contentful';

import { useIntl } from 'ha/i18n';

import { Breadcrumb } from 'ha/components/Breadcrumbs/types';
import { useRequiredHermes } from 'ha/modules/Hermes';
import { PRESS_RELEASE_BREADCRUMB } from 'ha/pages/Press/constants';

import { Breadcrumbs } from '../components/Breadcrumbs';

export interface Props {
  blogType: BlogType;
  isArticlePage?: boolean;
}

export const BreadcrumbsContainer: React.FC<Props> = ({
  blogType,
  isArticlePage = false,
}) => {
  const { T, urlResolver } = useIntl();
  const { data } = useRequiredHermes<BlogPageData>();
  const [breadcrumbs, setBreadcrumbs] = React.useState<Breadcrumb[]>([]);

  React.useEffect(() => {
    const breadcrumbList: Breadcrumb[] = [];

    const { compactTitle, country, city, landlordBlogUrl } = data;

    const addBreadcrumb = (name: string, path?: string) => {
      breadcrumbList.push({
        name,
        path:
          path && urlResolver.absolutizePath(path, { skipAddLanguage: true }),
      });
    };

    if (isArticlePage) {
      if (blogType === BlogType.PRESS) {
        addBreadcrumb(T(PRESS_RELEASE_BREADCRUMB), urlResolver.getPressUrl());
      }

      if (
        [BlogType.TENANT, BlogType.COUNTRY, BlogType.CITY].includes(blogType)
      ) {
        if (!country && !city) {
          addBreadcrumb(
            T('blog.breadcrumb.tenants_blog'),
            getTenantsGlobalBlogPath(),
          );
        }
      }

      if (blogType === BlogType.ADVERTISER) {
        if (landlordBlogUrl) {
          addBreadcrumb(T('blog.nav.blog'), landlordBlogUrl);
        }
      }

      if (country) {
        addBreadcrumb(country.name, country.pathname);
      }

      if (city) {
        addBreadcrumb(city.name, city.pathname);
      }
    } else if (blogType === BlogType.TENANT) {
      addBreadcrumb(
        T('blog.breadcrumb.tenants_blog'),
        getTenantsGlobalBlogPath(),
      );

      if (country) {
        addBreadcrumb(
          T('blog.breadcrumb.living_in_%s', country.name),
          `${getTenantsGlobalBlogPath()}${country.pathname}`,
        );
      }

      if (city) {
        addBreadcrumb(
          T('blog.breadcrumb.living_in_%s', city.name),
          `${getTenantsGlobalBlogPath()}${city.pathname}`,
        );
      }
    }

    if (compactTitle) addBreadcrumb(compactTitle);

    setBreadcrumbs(breadcrumbList);
  }, [blogType, isArticlePage, T, urlResolver, data]);

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
