import React from 'react';

import isString from 'lodash-es/isString';

import { Grid, Link, Typography, Tooltip, theme } from '@hbf/dsl/legacy';
import { getIsMobile } from 'ha/modules/UI/selectors';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { ScreenSize } from 'ha/modules/UI/constants';

interface Props {
  text: React.ReactNode;
  url?: string;
  renderAsLink: boolean;
}

const MAX_CHAR_LENGTH = 100;

const useStyles = makeStyles()(() => ({
  textWrapper: {
    display: 'flex',
  },
  textWrapperWithTooltip: {
    [theme.breakpoints.up(ScreenSize.MD)]: {
      display: 'block',
      maxWidth: `${MAX_CHAR_LENGTH}ch`,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

const BreadcrumbText: React.FC<{ text: Props['text'] }> = ({ text }) => {
  const { classes } = useStyles();
  const isMobile = useSelector(getIsMobile);

  let textElement = null;

  if (!isString(text) || isMobile) textElement = text;
  else if (isString(text) && text.length <= MAX_CHAR_LENGTH) textElement = text;
  else {
    textElement = (
      <Tooltip title={text} placement="bottom">
        <span className={classes.textWrapperWithTooltip}>{text}</span>
      </Tooltip>
    );
  }

  return <span className={classes.textWrapper}>{textElement}</span>;
};

const BreadcrumbItem: React.FC<Props> = ({ text, url, renderAsLink }) => {
  const isLink = renderAsLink && url;

  const textElement = (
    <Typography.Caption
      color={isLink ? 'default' : 'muted'}
      fontWeight={isLink ? 'bold' : 'normal'}
    >
      <Grid container>
        <BreadcrumbText text={text} />
      </Grid>
    </Typography.Caption>
  );

  return isLink ? <Link to={url}>{textElement}</Link> : textElement;
};

export { BreadcrumbItem };
