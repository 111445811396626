import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { ContentfulImg } from 'ha/modules/Contentful/components/ContentfulImg';
import { Typography } from '@hbf/dsl/legacy';

const useStyles = makeStyles()({
  container: {
    display: 'block',
    padding: '0.7rem 0',
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
});

interface Props {
  title?: string;
  src: string;
}

export const MarkdownImage: React.FC<Props> = ({ title, src }) => {
  const { classes, cx } = useStyles();

  return (
    <span className={classes.container}>
      <ContentfulImg className={cx(classes.image)} src={src} />
      {title && (
        <Typography.Paragraph component="span" color="muted">
          {title}
        </Typography.Paragraph>
      )}
    </span>
  );
};
