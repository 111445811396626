import { BlogType } from '@ha/contentful';

import {
  BLOG_ADVERTISER,
  BLOG_COUNTRY,
  BLOG_TENANT,
  BLOG_CITY,
} from 'ha/constants/pageNames';

export const getBlogCategory = (blogType?: BlogType) => {
  switch (blogType) {
    case BlogType.ADVERTISER:
      return BLOG_ADVERTISER;
    case BlogType.TENANT:
      return BLOG_TENANT;
    case BlogType.COUNTRY:
      return BLOG_COUNTRY;
    case BlogType.CITY:
      return BLOG_CITY;
    default:
      return BLOG_TENANT;
  }
};
