import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Grid, Typography, units } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';
import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { CustomContainer } from '../../CustomContainer';
import { Size } from '../constants';
import { LocationCard } from '../LocationCard';
import { FeaturedLocation } from '../types';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    padding: units.pxToRem(0, 16, 60),

    [theme.breakpoints.up('lg')]: {
      padding: units.pxToRem(0, 0, 40),
    },
  },
  container: {
    backgroundColor: theme.palette.mono.light,
    borderRadius: units.pxToRem(16),
    padding: units.pxToRem(24, 16),

    [theme.breakpoints.up('lg')]: {
      padding: units.pxToRem(40),
    },
  },
  subTitle: {
    fontFamily: interFont.fontFamily,
    fontWeight: 400,
    fontSize: units.pxToRem(14),
    lineHeight: 1.71,
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    fontSize: units.pxToRem(32),
    lineHeight: 1.37,
    letterSpacing: units.pxToRem(-1),
    color: theme.palette.secondary.main,
  },
}));

const getGridOptions = (columns: number) => {
  switch (columns) {
    case 4:
      return { spacing: 2, size: Size.Large };
    case 6:
      return { spacing: 1.5, size: Size.Small };
    default:
      return { spacing: 1, size: Size.Small };
  }
};

interface Props {
  title: string;
  subTitle: string;
  columns: number;
  locations: FeaturedLocation[];
}

const FeaturedLocations: React.FC<Props> = ({
  title,
  subTitle,
  columns,
  locations = [],
}) => {
  const { classes } = useStyles();

  if (!locations.length) {
    return null;
  }

  const { spacing, size } = getGridOptions(columns);

  return (
    <section
      data-test-locator="Blog/FeaturedLocations"
      className={classes.wrapper}
    >
      <CustomContainer className={classes.container}>
        <Grid container direction="column" rowGap={4}>
          <Grid item textAlign="center">
            <Typography.ParagraphTitle className={classes.subTitle}>
              {subTitle}
            </Typography.ParagraphTitle>

            <Typography.H2 className={classes.title}>{title}</Typography.H2>
          </Grid>

          <Grid item>
            <Grid
              container
              spacing={spacing}
              rowGap={{ lg: 2 }}
              justifyContent="center"
            >
              {locations.map(location => (
                <Grid item key={location.name}>
                  <LocationCard location={location} size={size} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CustomContainer>
    </section>
  );
};

export { FeaturedLocations };
