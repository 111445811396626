import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { BreadcrumbsWrapper, units } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';

import { Breadcrumbs as BasicBreadcrumbs } from 'ha/components/Breadcrumbs';
import { Breadcrumb } from 'ha/components/Breadcrumbs/types';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: `${units.pxToRem(8, 0, 8, 16)} !important`,
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      padding: `${units.pxToRem(16, 0, 16, 24)} !important`,
    },
  },
  breadcrumbs: {
    '& span': {
      fontFamily: interFont.fontFamily,
      fontSize: `${units.pxToRem(16)} !important`,
      lineHeight: 1.25,
      fontWeight: 600,
    },
    '& > a > span': {
      color: theme.palette.secondary.light,
    },
    '& > span': {
      color: theme.palette.secondary.main,
    },
  },
  separator: {
    color: theme.palette.secondary.light,
    '& > svg': {
      height: units.pxToRem(10),
    },
  },
}));

interface Props {
  breadcrumbs: Breadcrumb[];
}

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  const { classes } = useStyles();

  return (
    <BreadcrumbsWrapper classes={{ root: classes.root }}>
      <BasicBreadcrumbs
        breadcrumbs={breadcrumbs}
        classes={{
          li: classes.breadcrumbs,
          separator: classes.separator,
        }}
      />
    </BreadcrumbsWrapper>
  );
};

export { Breadcrumbs };
