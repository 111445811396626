import React from 'react';

import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet-async';
import { makeStyles } from 'tss-react/mui';

import { Breadcrumbs as MuiBreadcrumbs } from '@hbf/dsl/legacy';
import { HousingAnywhere } from '@hbf/icons/custom';
import { ArrowRight1 } from '@hbf/icons/streamline-bold';

import { useIntl } from 'ha/i18n';
import { getIsAdvertiser } from 'ha/modules/AuthLogic/selectors';
import { ScreenSize } from 'ha/modules/UI/constants';
import { notEmpty } from 'ha/utils/notEmpty';

import { BreadcrumbItem } from './BreadcrumbItem';
import { Breadcrumb } from './types';

export interface Props {
  breadcrumbs: Array<Breadcrumb | undefined>;
  withJsonLDSchema?: boolean;
  classes?: {};
}

export const getJsonLdBreadcrumbs = (breadcrumbs: Breadcrumb[]) =>
  JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map(
      ({ name, schemaName = name, path, schemaPath = path }, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': schemaPath,
          name: schemaName,
        },
      }),
    ),
  });

const useStyles = makeStyles()(theme => ({
  divider: {
    fontSize: 8,
  },
  ol: {
    whiteSpace: 'nowrap',
    flexWrap: 'initial',
  },
  nav: {
    [theme.breakpoints.down(ScreenSize.MD)]: {
      overflowX: 'auto',
      paddingBottom: theme.spacing(0.75),
    },
  },
}));

const Breadcrumbs: React.FC<Props> = ({
  breadcrumbs,
  withJsonLDSchema = true,
  classes: propClasses,
}) => {
  const filteredBreadcrumbs = breadcrumbs.filter(notEmpty);

  const isAdvertiser = useSelector(getIsAdvertiser);
  const { T, urlResolver } = useIntl();
  const { classes } = useStyles();

  return (
    <React.Fragment>
      {withJsonLDSchema && (
        <Helmet>
          <script type="application/ld+json" id="structuredDataBreadcrumbs">
            {getJsonLdBreadcrumbs([
              { name: T('Home'), path: urlResolver.getBaseUrl() },
              ...filteredBreadcrumbs,
            ])}
          </script>
        </Helmet>
      )}
      <MuiBreadcrumbs
        separator={<ArrowRight1 className={classes.divider} />}
        classes={{
          ol: classes.ol,
          root: classes.nav,
          ...propClasses,
        }}
        data-test-locator="Breadcrumbs"
      >
        <BreadcrumbItem
          text={<HousingAnywhere fontSize="inherit" />}
          url={urlResolver.getHomeUrl({ explicitNoRedirect: isAdvertiser })}
          renderAsLink
        />
        {filteredBreadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbItem
            key={breadcrumb.name}
            text={breadcrumb.name}
            url={breadcrumb.path}
            renderAsLink={index !== breadcrumbs.length - 1}
          />
        ))}
      </MuiBreadcrumbs>
    </React.Fragment>
  );
};

export { Breadcrumbs };
