import * as URL from 'url';
import * as queryString from 'query-string';

const plotlyUrlRegex = /^(https:)?\/\/plot.ly\/.+\.embed/;

export function isPlotlyUrl(url: string) {
  return plotlyUrlRegex.test(url);
}

// 'https://e.infogram.com/5edf638f-b773-41f2-b058-a629d35321e0?src=embed';
const infogramUrlRegex = /^(https:)?\/\/e.infogram.com\/.+src=embed/;

export function isInfogramUrl(url: string) {
  return infogramUrlRegex.test(url);
}

export function removePlotlyEditButton(url: string) {
  const parsed = URL.parse(url);

  const query = parsed.query ? queryString.parse(parsed.query) : {};
  const updatedQuery = {
    ...query,
    link: 'false',
  };

  return URL.format({ ...parsed, search: queryString.stringify(updatedQuery) });
}
