import React from 'react';

import { Helmet } from 'react-helmet-async';

import { PageInfo, SitemapStatus } from '@ha/contentful';

import { useTitle } from 'ha/helpers/hooks/useTitle';
import { useIntl } from 'ha/i18n';

import { LinkData } from 'ha/modules/Hermes/types';

interface Props {
  metaTitle: string;
  socialNetworkTitle?: string;
  metaDescription: string;
  linkData?: LinkData[];
  titleTemplateOn?: boolean;
  info?: PageInfo;
}

const HermesMetaInternal: React.FC<Props> = ({
  metaTitle,
  socialNetworkTitle,
  metaDescription,
  linkData,
  titleTemplateOn = true,
  info,
}) => {
  const title = useTitle(metaTitle, { withSuffix: titleTemplateOn });
  const { urlResolver } = useIntl();

  const isExcluded = info?.sitemap && info.sitemap === SitemapStatus.EXCLUDE;

  const canonical = React.useMemo(
    () => linkData?.find(link => link.rel === 'canonical'),
    [linkData],
  );
  const alternate = React.useMemo(
    () => linkData?.filter(link => link.rel === 'alternate'),
    [linkData],
  );

  return (
    <Helmet>
      <title key="title">{title}</title>
      <meta
        key="meta og:title"
        property="og:title"
        content={socialNetworkTitle || metaTitle}
      />
      <meta
        key="meta description"
        name="description"
        content={metaDescription}
      />
      <meta
        key="meta og:description"
        property="og:description"
        content={metaDescription}
      />
      {isExcluded || !canonical ? (
        <meta key="meta robots" name="robots" content="noindex" />
      ) : (
        <link
          rel="canonical"
          href={urlResolver.absolutizePath(canonical.href, {
            skipAddLanguage: true,
          })}
          key={`link ${canonical.rel}`}
        />
      )}

      {alternate?.length &&
        alternate.map(linkProps => (
          <link
            {...linkProps}
            href={urlResolver.absolutizePath(linkProps.href, {
              skipAddLanguage: true,
            })}
            key={`link ${linkProps.rel} ${
              linkProps.rel === 'alternate' ? ` ${linkProps.hrefLang}` : ''
            }`}
          />
        ))}
    </Helmet>
  );
};

const HermesMeta = React.memo(HermesMetaInternal);
export { HermesMeta };
