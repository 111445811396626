/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/core';

import { scriptjsWrapper as getScriptJs } from 'ha/helpers/scriptjsWrapper';
import { useIntl } from 'ha/i18n';

let globalId = 1;

const useStyles = makeStyles()(theme => ({
  errorMessage: {
    fontSize: units.pxToRem(14),
    fontWeight: 400,
    paddingLeft: 0,
    marginBottom: 0,
    listStyle: 'none',
    color: theme.palette.error.main,
  },
  errorInput: {
    borderColor: `${theme.palette.error.main} !important`,
  },
  form: {
    fontFamily: theme.typography.fontFamily,
    fontSize: units.pxToRem(18),
    marginBottom: units.pxToRem(24),
    whiteSpace: 'break-spaces',
    '& .hs-input:not([type="image"]):not([type="submit"]):not([type="button"]):not([type="radio"]):not([type="checkbox"]):not([type="file"])':
      {
        padding: units.pxToRem(6, 12),
      },
    '& .hs-form-booleancheckbox-display': {
      display: 'flex',
      alignItems: 'baseline',
      columnGap: units.pxToRem(10),
      marginTop: units.pxToRem(8),
      '> span': {
        margin: '0 !important',
      },
    },
    '& .hs-input[type="checkbox"], .hs-input[type="radio"]': {
      width: units.pxToRem(15),
      height: units.pxToRem(15),
    },
    '& .inputs-list': {
      padding: 0,
      listStyleType: 'none',
    },
    '& .hs-form-field': {
      marginBottom: units.pxToRem(16),
    },
    '& label': {
      fontWeight: 'normal',
    },
  },
  submitButton: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(theme.typography.button as any),
    marginTop: units.pxToRem(8),
    borderStyle: 'none',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    boxSizing: 'border-box',
    minWidth: units.pxToRem(64),
    padding: units.pxToRem(6, 16),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'capitalize',
    transition: theme.transitions.create(
      ['background-color', 'box-shadow', 'border'],
      {
        duration: theme.transitions.duration.short,
      },
    ),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&:active': {
      boxShadow: theme.shadows[8],
      outline: 'none',
    },
  },
}));

interface HubspotEvent {
  data: {
    type: string;
    eventName: string;
    id: string;
  };
}

interface Props {
  portalId: string;
  formId: string;
  submitText?: string;
  track: (eventName: string, params: {}) => void;
  testLocator?: string;
  disableCustomStyles?: boolean;
  isFormDefaultLabel?: boolean;
}

const HUBSPOT_FORM_SCRIPT_SRC = '//js.hsforms.net/forms/v2.js';

const HubspotForm: React.FC<Props> = ({
  formId,
  portalId,
  submitText,
  testLocator,
  track,
  disableCustomStyles = false,
  isFormDefaultLabel = false,
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [id, setId] = React.useState<number | undefined>(undefined);
  const { T, locale } = useIntl();
  const { classes } = useStyles();

  const onFormSubmit = React.useCallback(
    (event: HubspotEvent) => {
      if (
        !containerRef.current ||
        !(
          event.data &&
          event.data.type === 'hsFormCallback' &&
          event.data.eventName === 'onFormSubmit'
        )
      ) {
        return;
      }

      const emailInput: HTMLInputElement | null =
        containerRef.current.querySelector('[name=email]');

      if (!emailInput) {
        return;
      }
      track('Lead form submitted', {
        email: emailInput.value,
        formId,
        portalId,
      });
    },
    [track],
  );

  const containerId = `hubspot-form-container-${id}`;

  React.useEffect(() => {
    setId(globalId++);
  }, []);

  React.useEffect(() => {
    if (!id) {
      return;
    }

    getScriptJs(HUBSPOT_FORM_SCRIPT_SRC).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (global as any).hbspt.forms.create({
        portalId,
        formId,
        formInstanceId: id,
        target: `#${containerId}`,
        locale,
        ...(!disableCustomStyles && {
          css: '',
          cssClass: classes.form,
          errorMessageClass: classes.errorMessage,
          errorClass: classes.errorInput,
          submitButtonClass: classes.submitButton,
        }),
        translations: {
          [locale]: {
            submitText: submitText || T('Submit'),
            fieldLabels: {
              email: T('Email'),
              firstName: T('First name'),
              firstname: T('First name'), // Not sure why it camel case above, in hubspot we have this one 🤔
              phone: T('Phone number'),
              city: T('hubspot_form_input_city'),
              checkbox_subscription:
                !isFormDefaultLabel && T('hubspot_form_checkbox_subscription'),
              full_name_tbd_: T('Full name'),
              '0-2/name': T('Company name'),
              rooms_under_management: T('integrations.form.label.rooms'),
            },
          },
        },
      });
    });

    window.addEventListener('message', onFormSubmit);

    return () => window.removeEventListener('message', onFormSubmit);
  }, [id, submitText, formId, portalId]);

  if (!id) {
    return null;
  }

  return (
    <div ref={containerRef} id={containerId} data-test-locator={testLocator} />
  );
};

export { HubspotForm };
