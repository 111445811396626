const urlRegex =
  /^(?:http(?:s)??:\/\/)?(?:www\.|m\.)?(?:(?:youtube\.com\/watch\?v=)|(?:youtu.be\/))([a-zA-Z0-9\-_]+)$/;

export function isYoutubeVideo(url: string) {
  return urlRegex.test(url);
}

export function createEmbedUrl(url: string) {
  const groups = urlRegex.exec(url);

  if (!groups) {
    return null;
  }

  return `https://www.youtube.com/embed/${groups[1]}`;
}
