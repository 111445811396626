import * as URL from 'url';
import { isExternalUrl } from './isExternalUrl';
import { isInternalWithSubdomain } from './isInternalWithSubdomain';

export function fixHref(href: string) {
  let parsedHref = URL.parse(href, false, true);
  const shouldBeFixed = !parsedHref.protocol && href.indexOf('/') !== 0;

  if (shouldBeFixed) {
    const splits = href.split('/');
    const domain = splits[0];
    const pathname = `/${splits.slice(1).join('/')}`;

    parsedHref = URL.parse(`https://${domain}${pathname}`);
  }

  if (isExternalUrl(href) || isInternalWithSubdomain(href)) {
    return URL.format({ ...parsedHref, protocol: 'https' });
  }

  return `${parsedHref.pathname}${parsedHref.search || ''}`;
}
