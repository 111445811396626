import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { removePlotlyEditButton } from 'ha/utils/thirdParty/plots';

interface Props {
  src: string;
}

const useStyles = makeStyles()({
  container: {
    display: 'block',
    overflowX: 'auto',
  },
  content: {
    height: '50rem',
    width: '50rem',
  },
});

const Plot: React.FC<Props> = ({ src }) => {
  const { classes } = useStyles();

  return (
    // div breaks ssr when used inside "p" tag
    <span className={classes.container}>
      <iframe
        src={removePlotlyEditButton(src)}
        className={classes.content}
        frameBorder="0"
      />
    </span>
  );
};

export { Plot };
