import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  ul: {
    marginBottom: '40px',
  },
});

export const MarkdownUl: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return <ul className={classes.ul}>{children}</ul>;
};
