import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { BlogHomepageData } from '@ha/contentful';
import { Grid, Typography, units } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';

import { useIntl } from 'ha/i18n';

import { ArticleCard, Orientation, Size } from '../ArticleCard';
import { CustomContainer } from '../CustomContainer';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.pxToRem(0, 16, 60),

    [theme.breakpoints.up('lg')]: {
      padding: units.pxToRem(0, 0, 120),
    },
  },
  title: {
    fontFamily: interFont.fontFamily,
    fontWeight: 600,
    fontSize: units.pxToRem(32),
    lineHeight: 1.375,
    letterSpacing: units.pxToRem(-1),
    textAlign: 'center',
    color: theme.palette.secondary.main,
    marginBottom: units.pxToRem(32),
  },
}));

interface Props {
  articles: BlogHomepageData[];
}

const FeaturedArticles: React.FC<Props> = ({ articles = [] }) => {
  const { T } = useIntl();
  const { classes } = useStyles();

  if (!articles.length) {
    return null;
  }

  return (
    <section data-test-locator="Blog/FeaturedArticles">
      <CustomContainer className={classes.container}>
        <Typography.H2 className={classes.title}>
          {T('blog.main_page.section_trending')}
        </Typography.H2>
        <Grid
          container
          columns={{ xs: 1, md: 2 }}
          spacing={units.pxToRem(32)}
          data-test-locator="Blog/FeaturedArticles/List"
        >
          <Grid item xs={1} data-test-locator="Blog/FeaturedArticles/Item">
            <ArticleCard
              article={articles[0]}
              size={Size.Large}
              orientation={Orientation.Vertical}
            />
          </Grid>
          <Grid item xs={1}>
            <Grid
              container
              display="flex"
              direction="column"
              rowGap={{ xs: units.pxToRem(20), md: units.pxToRem(32) }}
            >
              {articles.slice(1).map(article => (
                <Grid
                  item
                  key={article.title}
                  data-test-locator="Blog/FeaturedArticles/Item"
                >
                  <ArticleCard article={article} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CustomContainer>
    </section>
  );
};

export { FeaturedArticles };
