export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum Orientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
