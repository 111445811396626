import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/legacy';

const useStyles = makeStyles()({
  root: {
    marginBottom: '1rem',
  },
});

export const MarkdownH1: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <Typography.H1 component="h1" className={classes.root}>
      {children}
    </Typography.H1>
  );
};
