import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Chip, units } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';

import { TopicClusterType } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.pxToRem(7, 11),
    borderRadius: units.pxToRem(100),
    height: 'unset',
  },
  selected: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  label: {
    fontFamily: interFont.fontFamily,
    fontWeight: 600,
    fontSize: units.pxToRem(14),
    lineHeight: 1.71,
    padding: 0,
  },
}));

const TopicCluster: React.FC<TopicClusterType> = ({
  label = 'label',
  isSelected = false,
  handleSelection = () => {},
}) => {
  const { classes, cx } = useStyles();

  return (
    <Chip
      clickable
      label={label}
      onClick={handleSelection}
      color={isSelected ? 'secondary' : 'default'}
      classes={{
        root: cx(classes.container, isSelected && classes.selected),
        label: classes.label,
      }}
      data-test-locator="Blog/TopicClusters/Item"
    />
  );
};

export { TopicCluster };
