import React from 'react';
import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t: Theme) => ({
  blockquote: {
    position: 'relative',
    color: t.palette.secondary.main,
    marginTop: '40px',
    marginBottom: '40px',
    [t.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  quoteStart: {
    fontSize: '10rem',
    position: 'absolute',
    lineHeight: '1rem',
    top: '3rem',
    [t.breakpoints.between('xs', 'md')]: {
      fontSize: '6rem',
      top: '2rem',
      left: '1rem',
    },
  },
  quote: {
    padding: '0 6rem',
    textAlign: 'center',
    '& > *': {
      color: t.palette.secondary.main,
    },
    [t.breakpoints.between('xs', 'md')]: {
      padding: '2rem',
    },
  },
  quoteEnd: {
    fontSize: '10rem',
    position: 'absolute',
    lineHeight: '1rem',
    bottom: 0,
    right: 0,
    [t.breakpoints.between('xs', 'md')]: {
      fontSize: '6rem',
      right: '1rem',
      bottom: '2rem',
    },
  },
}));

export const MarkdownBlockquote: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { classes } = useStyles();

  return (
    <blockquote className={classes.blockquote}>
      <div className={classes.quoteStart}>{'\u201c'}</div>
      <div className={classes.quote}>{children}</div>
      <div className={classes.quoteEnd}>{'\u201d'}</div>
    </blockquote>
  );
};
