import React from 'react';

import { BlogHomepageData } from '@ha/contentful';
import { Typography, units } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { ArticleCard, Size } from '../ArticleCard';

interface Props {
  articles: BlogHomepageData[];
}

export const RelatedArticles: React.FC<Props> = ({ articles }) => {
  const { T } = useIntl();

  return (
    <Grid
      container
      display="flex"
      direction="column"
      rowGap={units.pxToRem(32)}
    >
      <Typography
        variant="heading/desktop/h3-semibold"
        component="h3"
        color={theme => theme.palette.secondary.main}
      >
        {T('blog.section.related_articles')}
      </Typography>
      <Grid
        container
        display="flex"
        direction="column"
        rowGap={{ xs: units.pxToRem(16), md: units.pxToRem(32) }}
      >
        {articles.map(article => (
          <Grid item key={article.title}>
            <ArticleCard article={article} size={Size.Small} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
