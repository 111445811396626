import React from 'react';

import Alarm from '@mui/icons-material/Alarm';

import { makeStyles } from 'tss-react/mui';

import { BlogHomepageData } from '@ha/contentful';
import { Typography, units } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';

import { useIntl } from 'ha/i18n';

const useStyles = makeStyles()(theme => ({
  readingTimeAndDate: {
    fontFamily: interFont.fontFamily,
    color: theme.palette.neutral?.[50],
    padding: units.pxToRem(2, 0),
  },
}));

export type Props = Pick<BlogHomepageData, 'updatedAt' | 'readingTime'> & {
  isUpdated?: boolean;
  hideReadingTime?: boolean;
  children?: React.ReactNode;
};

const ReadingTimeAndDate: React.FC<Props> = ({
  children,
  isUpdated,
  updatedAt,
  readingTime,
  hideReadingTime,
}) => {
  const { classes } = useStyles();
  const { T, formatDate } = useIntl();
  const formatedDate = formatDate('D MMM YYYY', updatedAt);

  return (
    <Typography
      display="flex"
      flexWrap="wrap"
      component="div"
      alignItems="center"
      variant="body/sm-regular"
      columnGap={units.pxToRem(4)}
      className={classes.readingTimeAndDate}
    >
      <Alarm sx={{ fontSize: units.pxToRem(16) }} />
      {!hideReadingTime && (
        <React.Fragment>
          <Grid item flexShrink={0}>
            {T('%s min read', readingTime)}
          </Grid>
          <Grid item>{String.fromCharCode(183)}</Grid>
        </React.Fragment>
      )}
      <Grid item flexShrink={0}>
        {isUpdated
          ? `${T('blog_author_card_updated_text')} ${formatedDate}`
          : formatedDate}
      </Grid>
      {children}
    </Typography>
  );
};

export { ReadingTimeAndDate };
