import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Typography, Grid, units } from '@hbf/dsl/legacy';

import { Country } from '@ha/contentful';

import { haGroteskFont } from 'ha/constants/fonts/dslFonts';

import { useMediaQuery } from 'ha/helpers/MediaQuery';

import { CustomContainer } from '../CustomContainer';
import { DropdownMenu } from './DropdownMenu';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    backgroundColor: theme.palette.primary.accent,
  },
  container: {
    padding: units.pxToRem(16),

    [theme.breakpoints.up('lg')]: {
      padding: units.pxToRem(64, 0),
    },
  },
  title: {
    fontFamily: haGroteskFont.fontFamily,
    fontWeight: 700,
    fontSize: units.pxToRem(36),
    lineHeight: units.pxToRem(40),
    color: theme.palette.grey[800],
    padding: units.pxToRem(16, 0),

    [theme.breakpoints.up('lg')]: {
      fontSize: units.pxToRem(64),
      lineHeight: units.pxToRem(74),
      padding: 0,
    },
  },
}));

export interface Props {
  title: string;
  dropdown?: { title: string; countries: Country[] };
}

const HeroBanner: React.FC<Props> = ({ title = 'Title', dropdown }) => {
  const { classes } = useStyles();
  const { md } = useMediaQuery();

  const renderTitle = React.useCallback(
    () => <Typography.H1 className={classes.title}>{title}</Typography.H1>,
    [classes.title, title],
  );

  const withDropdown = dropdown && dropdown.countries.length > 0;

  return (
    <section data-test-locator="Blog/HeroBanner" className={classes.wrapper}>
      <CustomContainer className={classes.container}>
        {!withDropdown && renderTitle()}

        {withDropdown && (
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            wrap={md ? 'nowrap' : 'wrap'}
            columnGap={{ md: units.pxToRem(120) }}
          >
            <Grid
              item
              order={{ xs: 2, md: 1 }}
              xs={12}
              md="auto"
              style={{ flexShrink: 1 }} // flexShrink prop doesn't work
            >
              {renderTitle()}
            </Grid>
            <Grid
              item
              order={{ xs: 1, md: 2 }}
              xs={12}
              md="auto"
              sx={{
                padding: {
                  xs: units.pxToRem(12, 0),
                  md: units.pxToRem(16, 0, 0, 4),
                },
              }}
              data-test-locator="Blog/HeroBanner/Dropdown"
            >
              <DropdownMenu
                title={dropdown?.title}
                countries={dropdown?.countries}
              />
            </Grid>
          </Grid>
        )}
      </CustomContainer>
    </section>
  );
};

export { HeroBanner };
