import React from 'react';

import { BlogType } from '@ha/contentful';

import { haGroteskFont, interFont } from 'ha/constants/fonts/dslFonts';

import { MetaTags } from 'ha/types/MetaTags';

import { HermesMeta } from 'ha/components.legacy/HermesMeta';
import { useRequiredHermes } from 'ha/modules/Hermes';
import { Page as BasicPage } from 'ha/modules/Page';
import { PageProps } from 'ha/modules/Page/types';

import { Breadcrumbs } from '../Breadcrumbs';
import { HeroBanner, Props as HeroBannerProps } from '../HeroBanner/HeroBanner';

interface Props {
  page: PageProps;
  hero: HeroBannerProps;
  children: React.ReactNode;
  breadcrumbs?: { blogType: BlogType.ADVERTISER | BlogType.TENANT };
}

const Page: React.FC<Props> = ({ page, hero, breadcrumbs, children }) => {
  const {
    data: {
      meta: { title, description },
    },
    linkData,
    info,
  } = useRequiredHermes<{ meta: MetaTags }>();

  return (
    <BasicPage {...page} activeFonts={[haGroteskFont, interFont]}>
      <HermesMeta
        info={info}
        linkData={linkData}
        metaTitle={title || ''}
        metaDescription={description || ''}
      />
      {breadcrumbs && <Breadcrumbs blogType={breadcrumbs.blogType} />}
      <HeroBanner {...hero} />
      {children}
    </BasicPage>
  );
};

export { Page };
