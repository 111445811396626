import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { ArrowButtonDown, ArrowButtonUp } from '@hbf/icons/streamline-bold';
import { Button, Popover, units } from '@hbf/dsl/legacy';

import { Country } from '@ha/contentful';

import { DropdownMenuItem } from './DropdownMenuItem';

const useStyles = makeStyles()(theme => ({
  menuBtn: {
    padding: units.pxToRem(9, 11),
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.common.white,
    border: units.border(1, 'solid', theme.palette.grey[200]),
    fontSize: units.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.25,
    textTransform: 'none',
  },
  endIcon: {
    marginLeft: units.pxToRem(21),
    marginRight: units.pxToRem(4),
  },
  menu: {
    padding: units.pxToRem(12, 51, 12, 20),
    color: theme.palette.grey[500],
  },
  linkWrapper: {
    padding: units.pxToRem(8, 0),
  },
}));

interface Props {
  title: string;
  countries: Country[];
}

const DROPDOWN_MENU_ID = 'herobanner_country_dropdown';

const DropdownMenu: React.FC<Props> = ({ title, countries }) => {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const setAnchorElement = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(event => setAnchorEl(event.currentTarget), []);

  const removeAnchorElement = React.useCallback(() => setAnchorEl(null), []);

  const isOpen = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Button
        aria-haspopup="true"
        aria-owns={isOpen ? DROPDOWN_MENU_ID : undefined}
        className={classes.menuBtn}
        color="inherit"
        data-test-locator="HeroBanner/Dropdown/Btn"
        endIcon={isOpen ? <ArrowButtonUp /> : <ArrowButtonDown />}
        iconSize="small"
        onClick={setAnchorElement}
        classes={{ endIcon: classes.endIcon }}
      >
        {title}
      </Button>
      <Popover
        id={DROPDOWN_MENU_ID}
        open={isOpen}
        keepMounted
        disablePortal
        onClose={removeAnchorElement}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        PaperProps={{ elevation: 10 }}
      >
        <div
          className={classes.menu}
          data-test-locator="HeroBanner/Dropdown/Menu"
        >
          {countries.map(({ name, pathname }) => (
            <div className={classes.linkWrapper} key={name}>
              <DropdownMenuItem name={name} pathname={pathname} />
            </div>
          ))}
        </div>
      </Popover>
    </React.Fragment>
  );
};

export { DropdownMenu };
