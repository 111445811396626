import React from 'react';
import remark from 'remark';
import remarkSlug from 'remark-slug';
import reactRenderer from 'remark-react';

export interface Props {
  content: string;
  components?: object;
  className?: string;
  parsers?: Array<() => void>;
  'data-test-locator'?: string;
}

const Markdown: React.FC<Props> = ({
  content,
  components = {},
  parsers = [],
  className,
  ...props
}) => (
  <div className={className} {...props}>
    {
      remark()
        .use(remarkSlug)
        .use(parsers)
        .use(reactRenderer, {
          sanitize: { clobberPrefix: '' },
          toHAST: { allowDangerousHTML: true },
          remarkReactComponents: components,
        })
        .processSync(content).contents
    }
  </div>
);

export { Markdown };
