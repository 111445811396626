import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(t => ({
  li: {
    fontSize: units.rem(1.25),
    [t.breakpoints.down('md')]: {
      fontSize: units.rem(1),
    },
  },
}));

export const MarkdownLi: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return <li className={classes.li}>{children}</li>;
};
