import React from 'react';

import { useLocation } from 'react-router-dom';

import { Grid } from '@mui/material';

import CopyToClipboard from 'react-copy-to-clipboard';

import { units, Typography, IconButton } from '@hbf/dsl/core';
import { Facebook, Twitter, Link as LinkIcon } from '@hbf/icons/brand-bold';

import { useConfig } from 'ha/helpers/config';
import { useIntl } from 'ha/i18n';
import { ShareTargets, getShareUrls } from 'ha/utils/socialShareURLs';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

import { SocialShareButton, useStyles } from './SocialShareButton';

interface Props {
  blogTitle: string;
}

export const SocialShareButtons: React.FC<Props> = ({ blogTitle }) => {
  const { T, urlResolver } = useIntl();
  const {
    facebook: { app_id: facebookAppId },
  } = useConfig();
  const { pathname } = useLocation();
  const { classes } = useStyles();

  const basicShareUrl = `${urlResolver.getBaseUrl()}${pathname}`;

  const shareUrls = getShareUrls(basicShareUrl, blogTitle, facebookAppId);

  return (
    <Grid container direction="column" rowGap={units.pxToRem(16)}>
      <Grid item>
        <Typography variant="body/sm-regular" color="primary.dark">
          {T('blog.label_share_article')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container columnGap={units.pxToRem(12)}>
          <Grid item>
            <SocialShareButton
              socialVendor={ShareTargets.facebook}
              href={shareUrls.facebook}
            >
              <Facebook />
            </SocialShareButton>
          </Grid>

          <Grid item>
            <SocialShareButton
              socialVendor={ShareTargets.twitter}
              href={shareUrls.twitter}
            >
              <Twitter />
            </SocialShareButton>
          </Grid>

          <Grid item>
            <Track name="Clicked a copy" type="copy">
              <CopyToClipboard text={basicShareUrl}>
                <IconButton
                  className={classes.link}
                  component="a"
                  title="Copy to clipboard"
                >
                  <LinkIcon />
                </IconButton>
              </CopyToClipboard>
            </Track>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
