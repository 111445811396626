import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Typography, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(t => ({
  root: {
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '40px',

    [t.breakpoints.down('md')]: {
      fontSize: units.rem(1),
    },
  },
}));

export const MarkdownP: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <Typography.Paragraph component="p" className={classes.root}>
      {children}
    </Typography.Paragraph>
  );
};
