import { buildParams } from '@ha/search-query';

export enum ShareTargets {
  email = 'email',
  twitter = 'twitter',
  facebook = 'facebook',
  facebookSend = 'facebookSend',
  messenger = 'messenger',
  whatsapp = 'whatsapp',
  linkedin = 'linkedin',
  copy = 'copy',
}

export const FACEBOOK_APP_ID = '410300212483493';

export function getShareUrls(
  url: string,
  shareMessage?: string,
  fbAppID: string = FACEBOOK_APP_ID,
) {
  const message = shareMessage || global.document?.title;
  return {
    // https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent
    twitter: `https://twitter.com/intent/tweet${buildParams({
      url,
      text: message,
    })}`,
    // https://developers.facebook.com/docs/sharing/reference/feed-dialog
    facebook: `https://www.facebook.com/dialog/feed${buildParams({
      app_id: fbAppID,
      display: 'page',
      caption: message,
      link: url,
      redirect_uri: 'https://www.facebook.com/',
    })}`,
    email: `mailto:${buildParams({ subject: message, body: url })}`,
    // https://faq.whatsapp.com/en/general/26000030
    whatsapp: `https://wa.me/${buildParams({
      text: shareMessage ? [url, shareMessage].join(' ') : url,
    })}`,
    // https://developers.facebook.com/docs/sharing/messenger/web
    messenger: `fb-messenger://share${buildParams({
      link: url,
      app_id: fbAppID,
    })}`,
    // https://developers.facebook.com/docs/sharing/reference/send-dialog
    facebookSend: `http://www.facebook.com/dialog/send${buildParams({
      app_id: fbAppID,
      display: 'page',
      link: url,
      redirect_uri: 'https://www.facebook.com/',
    })}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/${buildParams({
      url,
      title: shareMessage,
    })}`,
  };
}

export type ShareUrls = ReturnType<typeof getShareUrls>;
