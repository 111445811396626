/**
 * Convert a two-letter ISO country code to an emoji flag.
 *
 * @param {string} isoCode - The two-letter ISO country code.
 * @returns {string} The emoji flag corresponding to the country code.
 *
 * @example
 * // Returns "🇺🇸" for the US country code.
 * const flag = countryToFlag('US');
 */
export const countryToFlag = (isoCode: string) => {
  const baseUnicodeNumber = 127397; // Root Unicode flags index
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + baseUnicodeNumber),
        )
    : isoCode;
};
