import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, Typography, units } from '@hbf/dsl/legacy';

import { ShortCountryData } from '@ha/contentful';

const useStyles = makeStyles()(theme => ({
  button: {
    padding: theme.spacing(0, 0, 1),
    transition: 'padding 300ms',
    position: 'relative',
    color: 'inherit',
    justifyContent: 'flex-start',
    minWidth: 'auto',

    '&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      height: '1px',
      width: 0,
      backgroundColor: theme.palette.orange.main,
      transition: 'width 300ms',
    },

    '&:hover': {
      color: theme.palette.grey[800],
      backgroundColor: 'transparent',
    },

    '&:hover:after': {
      width: '100%',
    },
  },
  text: {
    fontWeight: 600,
    fontSize: units.rem(0.875),
    lineHeight: 1.29,
  },
}));

const DropdownMenuItem: React.FC<
  Pick<ShortCountryData, 'name' | 'pathname'>
> = ({ name, pathname }) => {
  const { classes } = useStyles();

  return (
    <Button
      disableRipple
      variant="text"
      href={pathname}
      className={classes.button}
    >
      <Typography.ParagraphTitle
        color="inherit"
        textTransform="none"
        className={classes.text}
      >
        {name}
      </Typography.ParagraphTitle>
    </Button>
  );
};

export { DropdownMenuItem };
