import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { BlogHomepageData } from '@ha/contentful';
import { Grid, units, Typography, Button } from '@hbf/dsl/legacy';

import { interFont } from 'ha/constants/fonts/dslFonts';

import { useIntl } from 'ha/i18n';

import { ArticleCard, Size } from '../ArticleCard';
import { CustomContainer } from '../CustomContainer';

import { ARTICLES_SIZE } from './constants';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.pxToRem(0, 16, 60),

    [theme.breakpoints.up('lg')]: {
      padding: units.pxToRem(0, 0, 96),
    },
  },
  title: {
    fontFamily: interFont.fontFamily,
    fontWeight: 600,
    fontSize: units.pxToRem(32),
    lineHeight: 1.37,
    color: theme.palette.secondary.main,
    marginBottom: units.pxToRem(32),
    letterSpacing: units.pxToRem(-1),
  },
  articlesNumber: {
    fontFamily: interFont.fontFamily,
    fontSize: units.pxToRem(18),
    lineHeight: 1.55,
    color: theme.palette.secondary.light,
    marginLeft: units.pxToRem(8),
    letterSpacing: units.pxToRem(-0.2),

    [theme.breakpoints.up('lg')]: {
      fontSize: units.pxToRem(24),
      letterSpacing: units.pxToRem(-0.6),
    },
  },
  cta: {
    display: 'flex',
    width: '100%',
    fontFamily: interFont.fontFamily,
    fontSize: units.pxToRem(14),
    fontWeight: 600,
    lineHeight: 1.71,
    textTransform: 'none',
    margin: `${units.pxToRem(32)} auto 0`,
    padding: units.pxToRem(11, 16),
    borderRadius: units.pxToRem(4),
    border: `2px solid ${theme.palette.mono.dark} !important`,
    backgroundColor: 'transparent',

    [theme.breakpoints.up('sm')]: {
      width: 'unset',
      margin: `${units.pxToRem(48)} auto 0`,
    },

    '&:hover': {
      backgroundColor: theme.palette.mono.light,
    },
  },
}));

interface Props {
  title: string;
  articles: BlogHomepageData[];
}

const Articles: React.FC<Props> = ({
  title = 'title',
  articles: allArticles = [],
}) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  const [visibleArticles, setVisibleArticles] = React.useState<
    BlogHomepageData[]
  >([]);

  const showArticles = React.useCallback(
    (showMore = false) => {
      setVisibleArticles(prevVisibleArticles => {
        const offset = showMore ? prevVisibleArticles.length : 0;
        return allArticles.slice(0, ARTICLES_SIZE + offset);
      });
    },
    [allArticles],
  );

  const showMoreButton = React.useMemo(() => {
    if (allArticles.length <= ARTICLES_SIZE) {
      return false;
    }

    return allArticles.length !== visibleArticles.length;
  }, [allArticles.length, visibleArticles.length]);

  React.useEffect(() => {
    showArticles();
  }, [showArticles]);

  if (!visibleArticles.length) {
    return null;
  }

  return (
    <section data-test-locator="Blog/Articles">
      <CustomContainer className={classes.container}>
        <Typography.H2 className={classes.title}>
          {title}
          <span className={classes.articlesNumber}>{allArticles.length}</span>
        </Typography.H2>
        <Grid
          container
          columns={{ xs: 1, md: 2 }}
          columnSpacing={units.pxToRem(32)} // doesn't work as expected
          rowSpacing={{ xs: units.pxToRem(16), md: units.pxToRem(24) }}
          data-test-locator="Blog/Articles/List"
        >
          {visibleArticles.map(article => (
            <Grid
              item
              xs={1}
              key={article.title}
              data-test-locator="Blog/Articles/Item"
            >
              <ArticleCard size={Size.Medium} article={article} />
            </Grid>
          ))}
        </Grid>

        {showMoreButton && (
          <Button
            disableRipple
            variant="outlined"
            color="secondary"
            onClick={() => showArticles(true)}
            className={classes.cta}
          >
            {T('cta.show_more')}
          </Button>
        )}
      </CustomContainer>
    </section>
  );
};

export { Articles };
